import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import type { Lead } from '@magic/schemas/leads';
import { ChildHandshake, LocalHandle, WindowMessenger } from 'post-me';

import { useUI } from './context/ui';

export type WidgetMethods = {
  open: () => void;
  close: () => void;
};

export type WidgetEvents = {
  onOpenChanged: boolean;
  onRedirect: string;
  onLeftChanged: boolean;
  onLeadCaptured: Lead;
};

const messenger = new WindowMessenger({
  localWindow: window,
  remoteWindow: window.parent,
  remoteOrigin: '*',
});

const ChannelContext = createContext<LocalHandle<WidgetMethods, WidgetEvents>>(
  undefined!
);

export const useChannel = () => {
  return useContext(ChannelContext);
};

export const ChannelProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [handle, setHandle] = useState<
    LocalHandle<WidgetMethods, WidgetEvents>
  >(undefined!);

  const { setUI, ui } = useUI();

  useEffect(() => {
    handle?.emit('onOpenChanged', ui.open);
  }, [handle, ui.open]);

  useEffect(() => {
    handle?.emit('onLeftChanged', ui.left);
  }, [handle, ui.left]);

  const methods: WidgetMethods = useMemo(
    () => ({
      open: () => setUI((ui) => ({ ...ui, open: true })),
      close: () => setUI((ui) => ({ ...ui, open: false })),
    }),
    []
  );

  useEffect(() => {
    ChildHandshake(messenger, methods).then((connection) => {
      const handle = connection.localHandle();
      setHandle(handle);
    });
  }, [methods]);

  return (
    <ChannelContext.Provider value={handle}>{children}</ChannelContext.Provider>
  );
};
