import './init';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { ChannelProvider } from './channel';
import { UIProvider } from './context/ui';
import { router } from './router';

function Widget() {
  return (
    <UIProvider>
      <ChannelProvider>
        <Suspense>
          <RouterProvider router={router} />
        </Suspense>
      </ChannelProvider>
    </UIProvider>
  );
}

let container = null;

if (!container) {
  container = document.getElementById('root') as HTMLElement;
  ReactDOM.createRoot(container).render(<Widget />);
}
